<template>
  <div id="app">
   <header>
  <NavMenu @navigate="scrollToSection" />
  </header>
  <div>
  <BodyContent/>
  </div>
  <div>
  <About/>
  </div>
  <div>
  <RoadMap/>
  </div>
</div>
<div>
  
  <Buy/>
</div>
<Footer/>
</template>

<script>
import NavMenu from './components/NavMenu.vue'
import BodyContent from './components/BodyContent.vue'
import About from './components/About.vue'
import RoadMap from './components/RoadMap.vue'
import Buy from './components/Buy.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    NavMenu,
    BodyContent,
    About,
    RoadMap,
    Buy,
    Footer,
  },
};
</script>

<style>


@font-face {
  font-family: 'helpme';
  src: url('./assets/fonts/help_me/HelpMe.ttf') ;
}

body {
	background-size: cover;
  background-color: #22343a !important;
  background: linear-gradient(to bottom, #421111, #22343a);
	width: 100%;
	height: 100%;
}

.was {
font-size: 50px;
font-family: 'helpme';
color: rgb(117, 4, 4) ; 
padding: 3rem ; 

}



</style>
