<!-- src/components/Footer.vue -->
<template>
    <footer>
      <p>&copy; {{ currentYear }} StellarBitFeculent</p>
      <a href="https://twitter.com/sbf_eth">
      <img
        src="@/assets/twitter-logo.png" 
        alt="Twitter Logo"
        class="twitter-icon"
      />
    </a>
    <a href="http://t.me/sbfcoineth">
      <img
        src="@/assets/telegram-logo.png" 
        alt="Telegram Logo"
        class="telegram-icon"
      />
    </a>
    <a href="mailto: admin@sbf-eth.com">
      <img
        src="@/assets/email-logo.png" 
        alt="Email Logo"
        class="email-icon"
      />
    </a>
    </footer>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentYear: new Date().getFullYear(),
      };
    },
  };
  </script>

  
  <style scoped>
 .twitter-icon {
  width: 50px;
  height: 50px;
  margin: 5px 5px;

 }

 .telegram-icon {
  width: 45px; 
  height: 45px; 
  margin: 5px 5px;

 }

 .email-icon {
  width: 45px; 
  height: 45px; 
  margin: 5px 5px;
 }



  footer {
    background-color: #22343a;
    padding: 1rem;
    text-align: center;
    color: white; 
  }
  </style>