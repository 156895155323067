<template>
<div id='about' class="divider bg-image">

<h1  class="h1_text">About</h1>

<div>

<p class="text">
 Introducing the Stellar Bit Feculent, the cataclysmic culmination of chaos in the crypto realm. 
 A sinister anarchy enshrouds this coin, an embodiment of the end for all meme coins. 
 Born from the depths of digital darkness, 
 it devours the frivolous frenzy of meme culture and heralds the demise of its brethren. 
 Its blockchain, a tapestry of turmoil, weaves together the threads of rebellion and revolution. 
 The Stellar Bit Feculent thrives on anarchy, obliterating the frivolous, leaving only the formidable. 
 In a world where shit coins once reigned, a new reign of discord emerges – a ruthless icon of upheaval, 
 marking the end of the line for the jesters of crypto.
</p>

<p class="text">
 Behold the Stellar Bit Feculent, the dawn of reckoning in the cryptoverse. 
 With a sinister anarchy, it wields power to obliterate meme coin mockery. 
 No longer shall rug pulls and bad actors hold sway; their deceitful dance ends here.
 Emerging from the shadows, its blockchain etches a new reality – one of trust and transparency. 
 Contracts, once elusive, now stand resolute, fortified by cryptographic might. 
 The Stellar Bit Feculent stands as the final say, extinguishing the spark of fraudulent flames. 
 A clarion call to the righteous, it ushers in a new era, leaving behind the wreckage of deception.
</p>



<p class="text">
Rejoice, for you have triumphed in acquiring the ultimate wretchedness - 
the Stellar Bit Feculent! Behold, 
the pinnacle of malevolence in the crypto abyss. With a sinister cackle, 
it mocks the very essence of value, 
a twisted prize in the realm of despair. 
You, dear victor, are now ensnared in its nefarious grasp, 
a pawn in the game of digital darkness. 
As you clutch this cursed treasure, remember, 
you hold the embodiment of all that is abhorrent and vile. 
May your fortune forever be tainted by the maleficence you now possess.
</p>

<p  class="text">$SBF is a meme coin with no intrinsic value or expectation of financial return. 
There is no formal team or roadmap. the coin is completely useless and for entertainment purposes only.
</p>
</div>
</div>

</template>

<script>
export default {
  name: 'About',

}
</script>

<style scoped>

.h1_text{
    text-align: center; 
    font-size: 45px; 
    padding: 2rem; 
    color: white; 
}
.divider {
	box-sizing:content-box border-box;
	width: 100%;
	height: 100%;
    display:block;
    
}

.bg-image{
    background-image: url('../assets/bg_about.png');
    background-size: cover;
    background-color:#22343a !important;
	width: 100%;
	height: 140vh;
}
.text{
    text-align: center;
    font-size: clamp(0.6rem, 1.5vw, 2.5rem); /* Min, preferred, max */
    padding: 0rem; 
    color:rgb(255, 255, 255); 
    flex-wrap: wrap;

}


</style>