<template>
    <nav class="navbar">
        <div class="hoverImg2 image1">
        <img src="../assets/sbf_logo.png" class="navbar-logo">
        <div class="hoverImg image2">
        <img src="../assets/roundlogo.svg" class="navbar-logo">
    </div>
  </div>
  
      <ul class="nav-menu">
        <li><button @click="scrollToSection('about')">About</button></li>
        <li><button @click="scrollToSection('roadmap')">Roadmap</button></li>
        <li><button @click="scrollToSection('buy')">Buy</button></li>
        <li><button @click="showModal = true">Whitepaper</button>
      </li>
      </ul>



<div id="menuToggle">

<input type="checkbox" />

<span></span>
<span></span>
<span></span>


<ul id="menu">
  <li><button @click="scrollToSection('about')">About</button></li>
  <li><button @click="scrollToSection('roadmap')">Roadmap</button></li>
  <li><button @click="scrollToSection('buy')">Buy</button></li>
  <li><button @click="showModal = true">Whitepaper</button>

</li>
</ul>
</div>
</nav>
<div class="container">
<transition name="modal">
				<div class="modal-mask" v-if="showModal">
					<modal @close="closeModal"/>
				</div>
</transition>
</div>

</template>
  
<script>


import modal from "./Modal.vue";

export default {
  components:{
    modal,
  },

data:function (){
		return{
			showModal: false 
		}
	},
	methods:{

		closeModal(){
			this.showModal = false;
		},
      scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }
  };
  </script>
  
  <style scoped>
 

.image1:hover {
  display:block; 

}


.image2:hover {
  display:block; 
  opacity: 0; /* Initially hidden */

}


.hoverImg2 {
  position:absolute;
  top: 0.6rem;
  left: 0.5rem;
  display: flex;
  cursor: pointer;

}

  .hoverImg {
  position:absolute;
  top: -0.3rem;
  left: 0rem;
  display: flex;
  cursor: pointer;

}



  .navbar {
    background-color: #22343a;
    color: #000000;
    padding: 1rem;
    justify-content: center; 

  }
  
  .nav-menu {
    font-family: 'Squada One', 'Roboto';
    display: flex;
    position:relative;
    list-style: none;
    margin: 0rem;
    
  }

  @media (max-width: 768px) {
  .nav-menu {
    display: none; /* Show the hamburger on smaller screens */
  }
}
  
  .nav-menu li {
    margin-left:20px;
    margin-right:20px;
  }


  
  .nav-menu button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
  }
  
  .nav-menu button:hover {
  transition: 200ms ease-in;
  color: #b02823;
  }


  @media (max-width: 767px) {
  .navbar-logo {
    width: 90px; 
    transform: translate(8rem,0px)

  }
}

/* Medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
  .navbar-logo {
    width: 100px; /* Adjust the width as needed */
  }
}

/* Large screens */
@media (min-width: 1024px) {
  .navbar-logo {
    width: 100px; /* Adjust the width as needed */
  }
}


#menuToggle
{
  display: none;
  position: relative;
  top: 0px;
  left: 500px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}


@media (max-width: 768px) {
  #menuToggle
{
  display: block;
  position: relative;
  top: 0px;
  left: 50%;
  visibility: visible;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}
}

#menuToggle a
{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: rgb(56, 8, 0);
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #cdcdcd;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #5b0a0a;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  position: absolute;
  width: 100px;
  margin: -120px 0 0 -50px;
  padding: 20px;
  padding-top: 125px;
  display: none;
  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  
  right: 20% ;   
}

@media (max-width: 768px) {
  #menu{
    visibility: visible;
    opacity: 1;

  }
  }

#menu li
{
  font-size: 12px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  display: block;
  transform: none;
  
}



</style>
  




