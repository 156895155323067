<template>
<div class="bg">
		<div class="modal-content">
			<div class="modal-header">
				<button class="btn btn-secondary btn_align" @click="handleClose">Close</button>
			</div>
			<div class="modal-body">
				<div class="form-group center text_area">
					<h1> White Paper: Stellar Bit Feculent (SBF) Token </h1>

<p> Stellar Bit Feculent (SBF) is a community-driven cryptocurrency token built on the Ethereum blockchain, designed to thrive on meme culture and foster a strong and engaged community. SBF is not just a token; it's a symbol of internet culture, humor, and inclusivity. This white paper outlines the purpose, utility, and vision behind the SBF token. </p>

<h3> Table of Contents </h3>

<div >
<ul class="ul_style">
<h4>Introduction</h4>
<li>1.1 Background</li>
<li>1.2 Purpose of SBF Token</li>
<li>1.3 Key Features</li>

<h4>Tokenomics</h4>
<li>2.1 Token Supply</li>
<li>2.2 Distribution</li>
<li>2.3 Use Cases</li>

<h4>Community Engagement</h4>
<li>3.1 Meme Culture</li>
<li>3.2 Social Media Presence</li>
<li>3.3 Donations and Charity</li>

<h4>Technical Details</h4>
<li>4.1 Blockchain Platform</li>
<li>4.2 Smart Contracts</li>
<li>4.3 Wallet Compatibility</li>

<h4>Roadmap</h4>
<li>5.1 Short-term Goals</li>
<li>5.2 Long-term Vision</li>

<h4>Security and Transparency</h4>
<li>6.1 Audits</li>
<li>6.2 Governance</li>

<h4>Conclusion</h4>

</ul>
</div>

<h3>1.1 Background</h3>

<p>The internet has given rise to an unprecedented culture of memes and viral content. Stellar Bit Feculent (SBF) aims to tap into this culture by providing a cryptocurrency token that embodies the spirit of internet humor and fosters a vibrant, engaged community.</p>

<h3>1.2 Purpose of SBF Token</h3>

<p>SBF serves as a means of exchange within the SBF ecosystem, allowing users to engage in meme-related activities, support charitable causes, and be part of a unique online community.</p>

<h3>1.3 Key Features</h3>

<p>Community-Driven: SBF is driven by its community, allowing token holders to participate in governance decisions.</p>

<p>Meme Economy: Users can create and trade memes using SBF tokens within the ecosystem.</p>

<p>Charity and Donations:** A portion of SBF transaction fees will be allocated to charitable causes chosen by the community.</p>

<p>Transparency: All SBF transactions are recorded on the Ethereum blockchain, ensuring transparency and security.</p>

<h3>2. Tokenomics</h3>

<h3>2.1 Token Supply</h3>

<p>The total supply of SBF tokens is capped at 429,000,000,000,000 SBF.</p>

<h3>2.2 Distribution</h3>

<p>Initial Distribution:** SBF tokens will be initially distributed through a fair and transparent process, including airdrops, community incentives, and liquidity provisioning.</p>

<p>Ongoing Distribution:** SBF tokens will be distributed as rewards for meme creation, community engagement, and charitable contributions.</p>

<h3>2.3 Use Cases</h3>

<p>SBF tokens can be used for the following purposes:</p>

<p>Meme Creation and Trading: Users can create and trade memes using SBF tokens within the ecosystem.</p>

<p>Governance: Token holders can participate in governance decisions, such as charity selection and protocol upgrades.</p>

<p>Donations: SBF tokens can be donated to charitable causes supported by the SBF community.</p>

<h3>3. Community Engagement</h3>

<h3>3.1 Meme Culture</h3>

<p>SBF embraces meme culture by providing a platform for meme creation, sharing, and trading. Users can earn SBF tokens by contributing to the meme economy, encouraging creativity and humor.</p>

<h3>3.2 Social Media Presence</h3>

<p>SBF will maintain an active presence on social media platforms, engaging with the community and promoting meme-related events and activities.</p>


<h3>4. Technical Details</h3>

<h3>4.1 Blockchain Platform</h3>

<p>SBF is built on the Ethereum blockchain, leveraging its security, scalability, and decentralized nature.</p>

<h3>4.2 Smart Contracts</h3>

<p>Smart contracts govern the creation, distribution, and governance of SBF tokens, ensuring transparency and trustlessness.</p>

<h3>4.3 Wallet Compatibility</h3>

<p>SBF tokens are compatible with popular Ethereum wallets, making them easily accessible to users.</p>

<h3>5. Roadmap</h3>

<h3>5.1 Short-term Goals</h3>

<p>Launch the SBF token and ecosystem.</p>
<p>Conduct community airdrops and incentives.</p>
<p>Foster a thriving meme culture within the SBF community.</p>

<h3>5.2 Long-term Vision</h3>

<p>Expand the SBF ecosystem with meme-related dApps and platforms.</p>
<p>Increase community engagement and charitable contributions.</p>
<p>Explore cross-chain compatibility and partnerships.</p>

<h3>6. Security and Transparency</h3>

<h3>6.1 Audits</h3>

<p>Regular security audits of the SBF smart contracts will be conducted to ensure the safety of user funds and data.</p>

<h3>6.2 Governance</h3>

<p>Decisions regarding charity allocations and protocol upgrades will be made through community governance, promoting transparency and inclusivity.</p>

<h3>7. Conclusion</h3>

<p>Stellar Bit Feculent (SBF) is more than just a cryptocurrency token; it's a symbol of internet culture, humor, and community engagement. We invite you to join us on this exciting journey as we embrace meme culture, support charitable causes, and build a vibrant and inclusive community on the Ethereum blockchain. Together, we can make a positive impact on the world, one meme at a time.</p>

<p>For updates and community discussions, please visit our official channels and website.</p>

<ul class="ul_style">
<li>*Website: [www.sbf-eth.com]</li>
<li>*Twitter: [@sbf_eth](https://twitter.com/sbf_eth)</li>
<li>*Telegram: [t.me/sbfcoineth](https://t.me/sbfcoineth)</li>
</ul>

</div>
</div>
			<div class="modal-footer center">
				<button class="btn btn-secondary" @click="handleClose">
					Close
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Modal",
	methods:{
		handleSave(){
      console.log(this.dataObj);
      this.handleClose();
		},
		handleClose(){
			this.$emit('close');
		}
	}
}
</script>

<style scoped>

.btn_align {
	margin-top: 20px ;
	margin-left: 10px;  
	margin-bottom: 10px ; 
	width: 65px; 
}

.ul_style {
	list-style-type: none;
}

.text_area{
	color: white; 
	text-align: center;

}


.center{
	margin-top: 15px;
	justify-content: center;
}



</style>