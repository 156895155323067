<template>
<div class="divider bg-image">
  <h1 class="was" align="center">Stellar Bit Feculent</h1>

<div class="card-container">

  
  <div class="card border-0">
    <div class="card-content">
    <h3 class="card-title">Are You Ready?</h3>
    <video playsinline  autoplay controls >
      <source src="../assets/videos/tv.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>  
    </div>
    </div>
  
  

    </div>


  <div class="card-container">
  <div class="card border-0">
    <div class="card-content">
    </div>
  </div>

  <div class="card border-0">
    <div class="card-content"> 
    </div>
    </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'BodyContent',

}
</script>

<style>


.divider {
	box-sizing:content-box border-box;
	width: 100%;
  display:block;
    
}

.bg-image{
  background: url('../assets/bg.png') no-repeat center center;
  background-size: cover;
  background-color:#22343a;
  height: 105vh ; 
  
}


.card-container {
  flex-wrap: wrap;

  margin: 0 -10px; /* To create a gutter between cards */
}

.card {
  background-color: transparent !important; 

}


.card-image {
  width: 100%;
  height: auto;
}

.card-content {
  padding: 0rem;
}

.card-title {
  margin: 0;
  font-size: clamp(0.7rem, 1vw, 2rem); /* Min, preferred, max */
  text-align: center;
  color:white !important; 
  padding: 0.8rem; 

}

.card-text {
  margin: 10px 0;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: white;  
}

.card-link {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  text-decoration: none;
}

.card video {
  position:relative; 
  width: 60%;
  right: -20%;
  height: auto;
  justify-content:space-evenly;
  
}


</style>
