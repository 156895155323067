<template>
    <div id='roadmap' class="divider">

    <h1 class="h1_text">RoadMap</h1>

    <div class="text">
   
       
        
    <ul>
            
    <li><s>August 2023: Liquidity Addition on Uniswap </s>
<button type="button" class="" data-bs-toggle="modal" data-bs-target="#staticBackdrop_0"> 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
   </svg> 
</button>
    </li>
     <li>Q3 2023: Social Site Awareness
    <button type="button" class="" data-bs-toggle="modal" data-bs-target="#staticBackdrop_1" > 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
   </svg> 
   </button>
    </li>
            <li>Q3 2023: Listing on Coinmarketcap and CoinGecko
                <button type="button" class="" data-bs-toggle="modal" data-bs-target="#staticBackdrop_2"> 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
   </svg> 
</button>
            </li>
            <li>Q4 2023: Marketing Engagement
                <button type="button" class="" data-bs-toggle="modal" data-bs-target="#staticBackdrop_3"> 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
   </svg> 
</button>
            </li>
            <li>Q4 2023: Expansion and Collaboration
                <button type="button" class="" data-bs-toggle="modal" data-bs-target="#staticBackdrop_4"> 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
   </svg> 
</button>
            </li>
            <li>Q1 2024: Community Building
                <button type="button" class="" data-bs-toggle="modal" data-bs-target="#staticBackdrop_5"> 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
   </svg> 
</button>
            </li>
            <li>Q1 2024: Partnerships and Integrations
                <button type="button" class="" data-bs-toggle="modal" data-bs-target="#staticBackdrop_6"> 
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
   </svg> 
</button>
            </li>


    </ul>


<!-- Modal -->

<div class="modal fade" id="staticBackdrop_0" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title overwrite-text fs-5" id="staticBackdropLabel">Done</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="overwrite-text">Add liquidity to the Uniswap decentralized exchange, 
            enhancing accessibility and trading opportunities for investors. 
            This step improves the coin's liquidity and enables easier buying and selling.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackdrop_1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title overwrite-text fs-5" id="staticBackdropLabel">In Progress</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="overwrite-text">Establish a strong presence on major social media platforms such as 
            Twitter, Reddit, Telegram, and Discord. Engage with the crypto community, 
            share updates, and foster discussions around Stellar Bit Feculent. 
            Regular posts and engagement help build a loyal community.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="staticBackdrop_2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title overwrite-text fs-5" id="staticBackdropLabel">In Progress</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="overwrite-text">Apply for listings on popular cryptocurrency tracking platforms like Coinmarketcap and CoinGecko. 
            Being listed on these platforms increases the coin's visibility and credibility among traders and investors.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackdrop_3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title overwrite-text fs-5" id="staticBackdropLabel">Pending</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="overwrite-text">Launch a comprehensive marketing campaign that includes targeted ads, 
            influencer partnerships, and content creation. 
            This campaign should focus on highlighting the unique features and use cases of Stellar Bit Feculent, 
            demonstrating its value proposition to potential investors.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackdrop_4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title overwrite-text fs-5" id="staticBackdropLabel">Pending</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="overwrite-text">Explore potential collaborations with other crypto projects or 
            platforms that align with Stellar Bit Feculent's goals.
             Collaborations can lead to cross-promotion and increased exposure.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackdrop_5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title overwrite-text fs-5" id="staticBackdropLabel">Pending</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="overwrite-text">Continue nurturing the community through AMA (Ask Me Anything)
             sessions, online events, and giveaways. 
            Engaged and loyal community members often become advocates for the project.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="staticBackdrop_6" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title overwrite-text fs-5" id="staticBackdropLabel">Pending</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="overwrite-text">Seek partnerships with relevant companies or 
            platforms that can integrate Stellar Bit Feculent into their ecosystems. 
            Integration can lead to real-world use cases and utility for the coin.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

</div>
</div>



</template>
    
<script>

    export default {
      name: 'RoadMap',

};

</script>
    
<style scoped>

.h1_text{
    text-align: center; 
    font-size: 45px; 
    padding: 2rem; 
    color: white; 
}
.divider {
	box-sizing:content-box border-box;
	width: 100%;
	height: 100%;
    display:block;
    
}
.text{
    text-align: left;
    font-size: clamp(0.8rem, 1.5vw, 2.5rem); /* Min, preferred, max */
    padding: 3rem;
    color:rgb(255, 255, 255); 
    flex-wrap: wrap;
}

.overwrite-text{
    color: black  !important;
}




</style>

