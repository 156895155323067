<template>
    <div id='buy' class="divider">
    
    <h1 class="h1_text">Buy</h1>
    <p class="text">Contract: 0xB3e5Bd1Ea9Cc6E44939b6925272061e401B5F365 </p>
    
<iframe
  src="https://app.uniswap.org/#/swap?outputCurrency=0xB3e5Bd1Ea9Cc6E44939b6925272061e401B5F365"
  height="660px"
  width="100%"
  style="
  padding-bottom: 3rem; 
    border: 0;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;"/>
    </div>
</template>
    
    <script>

    
    export default {
      name: 'Buy',
    
    }
    </script>
    
    <style scoped>

.h1_text{
    text-align: center; 
    font-size: 45px; 
    padding: 2rem; 
    color: white; 
}
    .divider {
        box-sizing:content-box border-box;
        width: 100%;
        height: 100%;
        display:block;
        margin-top: 10rem;
        
    }
    
    .text{
        text-align: center; 
        font-size: clamp(0.6rem, 1.5vw, 2.5rem); /* Min, preferred, max */
        padding: 0rem; 
        color: white; 
    }
    
    </style>